import { Box } from '@mui/material';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { theme } from '@portals/core/src/themes/sde/main';
import React from 'react';

import { EyeCatcher, EyeCatcherProps, MainFactProps, MainFacts } from '../../components';
import { EstateItemHeader } from './EstateItemHeader/EstateItemHeader';
import { ImageContainer } from './ImageContainer/ImageContainer';

export type EstateListItemProps = {
  ref?: any;
  id: string;
  title: string;
  subtitle: string;
  images: string[];
  price: string;
  eyeCatcher?: EyeCatcherProps[];
  mainFacts: MainFactProps[];
  partnerLogo?: string;
  bookmarkButton?: React.ReactNode;
  isLastItem?: boolean;
};

export const EstateListItem: React.FC<EstateListItemProps> = React.forwardRef(
  ({ id, title, subtitle, images, price, eyeCatcher, mainFacts, partnerLogo, bookmarkButton, isLastItem }, ref) => {
    return (
      <Box
        data-id="estate-list-item"
        ref={ref}
        className="estate-list-item-row"
        display="flex"
        alignItems="stretch"
        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
        flexGrow={1}
        sx={{
          py: 4,
        }}
      >
        <ImageContainer
          title={title}
          imageList={images}
          partnerLogo={partnerLogo}
          estateId={id}
          bookmarkButton={bookmarkButton}
        />
        <Box
          className="estate-list-item-info-wrapper"
          borderBottom={{ sm: isLastItem ? `none` : `1px solid ${theme.palette.grey[300]}` }}
          marginLeft={{ xs: 0, md: 6, lg: 5 }}
          overflow="hidden"
          width="100%"
        >
          <Box className="estate-list-item-info-content" display="flex" flexDirection="column" height={1}>
            <Box data-testid="estateListItemHeader" pt={2} mb="auto">
              <EstateItemHeader title={title} subtitle={subtitle} />
              <EyeCatcher list={eyeCatcher} />
            </Box>
            <Box data-testid="estateListItemFacts" pt={{ xs: 5 }} paddingBottom={{ xs: 2, lg: 3 }}>
              <MainFacts list={mainFacts} />
              <Typography display="inline" variant="h4">
                {price}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

EstateListItem.displayName = 'EstateListItem';
