import { Box, Grid, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { isIF6Context } from '@portals/core-immobilien/src/utils';
import { ChevronDown, Home } from '@portals/icons';
import {
  EstateSearchProps,
  MarketingType,
  ObjectType,
  UsageType,
} from '@portals/sip-client-data/src/general/ApiClientTypes';
import { Form, Formik } from 'formik';
import { assign, get, isEqual, keys, pick } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { stringifyUrl } from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { number, object, string } from 'yup';

import { getAllMarketingTypes } from '../../config/types/marketingType';
import { getPerimeterLabelFromOptionsByValue, getPerimeterOptions } from '../../config/types/perimeter';
import { useEstateSearchLocalStorage } from '../../hooks/useEstateSearchLocalStorage/useEstateSearchLocalStorage';
import {
  fieldHasValue,
  getMarketingTypeObjectTypeLabel,
  getObjectTypeOptions,
  prefillDefaultSearchValues,
  removeEmptyParams,
  removeNotWhitelistedParams,
} from '../../utils/searchParamUtils';
import { ButtonGroupSelectTextField } from '../ButtonGroupSelect/ButtonGroupSelectTextField/ButtonGroupSelectTextField';
import { HitButton } from '../HitButton/HitButton';
import { EstateSearchFilterView } from './EstateSearchFilterView';
import { CustomSelect } from './EstateSelect';
import { ZipCityEstateIdAutocomplete } from './ZipCityEstateIdAutocomplete';

export interface Props {
  perimeter?: number;
  updateEstateSearchHandler?: (params: EstateSearchProps) => void;
}
export interface EstateSearchMainFormProps {
  zipCityEstateId: string;
  perimeter: number;
  marketingType: MarketingType;
  lastClickedMarketingTypeValue: MarketingType;
  objectType: ObjectType;
  usageType: UsageType;
  marketingTypeObjectTypeTextfieldValue: string;
  maxPrice?: number;
  maxMarketValue?: number;
  maxRent?: number;
  maxLease?: number;
  minLivingSpace?: number;
  minNumberRooms?: number;
  minTotalSpace?: number;
  minPropertySize?: number;
}

export const EstateSearch = (props: Props): React.ReactElement => {
  const router = useRouter();
  const { t } = useTranslation();
  const theme = useTheme();
  const isIF6Scope = isIF6Context();
  const { lastSearchQuery, updateLastSearchQuery } = useEstateSearchLocalStorage();
  const defaultValues: EstateSearchMainFormProps = {
    zipCityEstateId: '',
    perimeter: props.perimeter ?? 10,
    marketingType: MarketingType.BUY,
    lastClickedMarketingTypeValue: MarketingType.BUY,
    objectType: ObjectType.FLAT,
    usageType: UsageType.RESIDENTIAL,
    marketingTypeObjectTypeTextfieldValue: getMarketingTypeObjectTypeLabel(t, MarketingType.BUY, ObjectType.FLAT),
    maxPrice: undefined,
    maxMarketValue: undefined,
    maxRent: undefined,
    maxLease: undefined,
    minLivingSpace: undefined,
    minNumberRooms: undefined,
    minTotalSpace: undefined,
    minPropertySize: undefined,
  } as const;

  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState<EstateSearchMainFormProps>(defaultValues);
  const [isReadyToRender, setIsReadyToRender] = useState(false);

  const basicValidationSchema = {
    perimeter: number().integer().min(0).max(999999999).nullable(),
    zipCityEstateId: string().strict().min(1).required(t('estateSearch.errorRequiredWhere')),
  };

  const setInititalValuesfromLocalStorage = () => {
    const selectedCategory = {
      lastClickedMarketingTypeValue: get(lastSearchQuery, 'marketingType', defaultValues.marketingType),
      marketingTypeObjectTypeTextfieldValue: getMarketingTypeObjectTypeLabel(
        t,
        get(lastSearchQuery, 'marketingType', defaultValues.marketingType),
        get(lastSearchQuery, 'objectType', defaultValues.objectType)
      ),
    };

    const allowedSearchFormProps = pick(lastSearchQuery, keys(defaultValues));
    const initialSearchFormProps = { ...allowedSearchFormProps, ...selectedCategory } as EstateSearchMainFormProps;
    setInitialValues(initialSearchFormProps);
  };

  useEffect(() => {
    if (lastSearchQuery) {
      setInititalValuesfromLocalStorage();
    }
    setIsReadyToRender(true);
  }, []);

  const [isExtendedFieldsButtonClicked, setIsExtendedFieldsButtonClicked] = useState(false);

  const showExtendedFieldsOnClick = useCallback(() => {
    setIsExtendedFieldsButtonClicked(true);
  }, []);

  const ExtendFieldsButton = (): JSX.Element => {
    return (
      <Button
        className="extended-options-button"
        sx={{ '&& .MuiButton-endIcon > *:nth-of-type(1)': { fontSize: 'inherit' } }}
        variant="inverted"
        endIcon={<ChevronDown />}
        onClick={showExtendedFieldsOnClick}
      >
        {t('estateSearch.moreOptions')}
      </Button>
    );
  };

  const checkIfTypeIsOther = (value) => {
    return value === Number.MAX_VALUE ? null : value;
  };

  const FormikOnChange = ({ values, callback }) => {
    useEffect(() => {
      callback(values);
    }, [values]);

    return null;
  };

  const persistQuery = (values: EstateSearchMainFormProps) => {
    const defaultSearchValues = prefillDefaultSearchValues(values);
    const whitelistedApiParams = removeNotWhitelistedParams(defaultSearchValues);
    const queryParamsWithoutEmpty = removeEmptyParams(whitelistedApiParams);

    if (!isEqual(queryParamsWithoutEmpty, lastSearchQuery)) {
      updateLastSearchQuery(queryParamsWithoutEmpty);
    }
  };

  return (
    <Box
      data-id="estate-search"
      className="search-form"
      sx={{
        minWidth: { xs: '320px', md: '704px', lg: '885px' },
        maxWidth: { xs: '431px', md: '100%', lg: '100%' },
        minHeight: { xs: '225px', md: '113px', lg: '177px' },
        width: { xs: '100%', md: '704px', lg: '885px' },
        height: 'auto',
        margin: '0 auto',
        paddingX: {
          xs: theme.spacing(5),
          md: theme.spacing(8),
        },
        paddingBottom: {
          lg: theme.spacing(6),
        },
        marginTop: {
          xs: theme.spacing(9),
          md: theme.spacing(7),
          lg: theme.spacing(6),
        },
        backgroundColor: 'transparent',
      }}
    >
      {isReadyToRender && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={object().shape(validationSchema)}
          onSubmit={async (values) => {
            const defaultSearchValues = prefillDefaultSearchValues(values);
            const whitelistedApiParams = removeNotWhitelistedParams(defaultSearchValues);
            const queryParamsWithoutEmpty = removeEmptyParams(whitelistedApiParams);
            if (isIF6Scope) {
              props.updateEstateSearchHandler(queryParamsWithoutEmpty);
            } else {
              const url = stringifyUrl({ url: `/immobilien/treffer`, query: queryParamsWithoutEmpty });
              router.push(url);
            }
          }}
        >
          {({ values, setFieldTouched, setFieldValue, isValid }) => (
            <Form>
              <FormikOnChange values={values} callback={persistQuery} />
              <Box>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <Box
                      position="relative"
                      sx={{
                        '.MuiAutocomplete-root .MuiAutocomplete-input': { marginRight: '25%' },
                        '.MuiAutocomplete-root .MuiOutlinedInput-notchedOutline': { borderRight: 'none' },
                        mr: { md: 3 },
                        mb: { xs: 5, md: 0 },
                      }}
                    >
                      <ZipCityEstateIdAutocomplete fieldName="zipCityEstateId" />
                      <Box position="absolute" top="0" right="0" width={{ xs: '40%', md: '27%', lg: '27%' }}>
                        <CustomSelect
                          testId="perimeter"
                          initialValue={values.perimeter}
                          items={getPerimeterOptions(t)}
                          onChange={(value) => {
                            setFieldTouched('perimeter', true);
                            setFieldValue('perimeter', checkIfTypeIsOther(value), true);
                          }}
                          formatValue={(value) => getPerimeterLabelFromOptionsByValue(t, value)}
                          selectProps={{
                            variant: 'outlined',
                            className: 'perimeter-select',
                          }}
                          selectStyles={{
                            borderTopLeftRadius: '0',
                            borderBottomLeftRadius: '0',
                            '&& .MuiSelect-select': {
                              minHeight: 'inherit',
                            },
                            '.MuiSelect-icon': {
                              padding: 0,
                              pointerEvents: 'none',
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Box ml={{ md: 3 }}>
                      <ButtonGroupSelectTextField
                        InputAdornmentIcon={Home}
                        label={t('what')}
                        inputValue={values.marketingTypeObjectTypeTextfieldValue}
                        buttonOptions={getAllMarketingTypes(t)}
                        buttonValue={values.marketingType}
                        selectOptions={getObjectTypeOptions(t, values.marketingType)}
                        selectValue={values.objectType}
                        highlightSelectedOption={values.marketingType === values.lastClickedMarketingTypeValue}
                        onCloseHandler={() => {
                          if (values.marketingType !== values.lastClickedMarketingTypeValue) {
                            setFieldTouched('marketingType', true);
                            setFieldValue('marketingType', values.lastClickedMarketingTypeValue, true);
                          }
                        }}
                        onButtonClick={(value) => {
                          setFieldTouched('marketingType', true);
                          setFieldValue('marketingType', value, true);
                          return false;
                        }}
                        onSelectClick={(selectedMenuItem) => {
                          setFieldTouched('lastClickedMarketingTypeValue', true);
                          setFieldValue('lastClickedMarketingTypeValue', values.marketingType, true);
                          setFieldTouched('objectType', true);
                          setFieldValue('objectType', selectedMenuItem, true);
                          setFieldValue(
                            'marketingTypeObjectTypeTextfieldValue',
                            getMarketingTypeObjectTypeLabel(t, values.marketingType, selectedMenuItem),
                            false
                          );
                          return true;
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container paddingTop={{ xs: 3, md: 2 }}>
                  <Grid
                    item
                    xs={12}
                    marginTop={{ xs: 4, md: 2 }}
                    display={isExtendedFieldsButtonClicked ? 'none' : { xs: 'inline-grid', md: 'none', lg: 'none' }}
                  >
                    <ExtendFieldsButton />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={8}
                    lg={8}
                    spacing={3}
                    paddingRight={{ md: 5, lg: 5 }}
                    display={
                      isExtendedFieldsButtonClicked
                        ? { xs: 'inline-grid' }
                        : { xs: 'none', md: 'inherit', lg: 'inherit' }
                    }
                    gridTemplateColumns="100%"
                    sx={{
                      '.MuiGrid-item': { width: isExtendedFieldsButtonClicked ? 'auto' : 0 },
                      '.MuiInputLabel-shrink': { top: theme.spacing(2) },
                    }}
                  >
                    <EstateSearchFilterView
                      setValidationSchema={(subfieldValidationSchema) => {
                        const currentValidationSchema = assign({}, basicValidationSchema, subfieldValidationSchema);
                        if (!isEqual(Object.keys(currentValidationSchema), Object.keys(validationSchema))) {
                          setValidationSchema(currentValidationSchema);
                        }
                      }}
                      marketingType={values.lastClickedMarketingTypeValue}
                      objectType={values.objectType}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} marginTop={{ xs: 4, md: 2, lg: 2 }}>
                    <HitButton disabled={!fieldHasValue(values.zipCityEstateId) || !isValid} />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

EstateSearch.displayName = 'EstateSearch';
