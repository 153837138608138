import { Box } from '@mui/material';
import { useAspectRatio } from '@portals/core/src/hooks/useAspectRatio';
import { theme } from '@portals/core/src/themes/immobilien/main';
import React from 'react';

import { generateImageUrls } from '../../../utils/imageUtils';

export interface MediaProps {
  alternativeText: string;
  url: string;
}

export interface AspectMediaProps {
  media: MediaProps;
  aspectWidth: number;
  aspectHeight: number;
}

export const AspectMedia: React.FunctionComponent<AspectMediaProps> = ({ media, aspectWidth, aspectHeight }) => {
  const { webpUrl, defaultUrl, defaultImageType } = generateImageUrls(media.url);
  const { ref, style } = useAspectRatio<HTMLDivElement>(
    { width: aspectWidth, height: aspectHeight },
    { width: aspectWidth, height: aspectHeight }
  );

  return (
    <Box sx={style} ref={ref}>
      <picture>
        {webpUrl && <source srcSet={webpUrl} type="image/webp" />}
        {defaultUrl && (
          <>
            <source srcSet={defaultUrl} type={defaultImageType} />
            <img
              src={defaultUrl}
              alt={media.alternativeText}
              style={{
                height: '100%',
                width: '100%',
                display: 'block',
                objectFit: 'cover',
                textAlign: 'center',
                color: theme.palette.common.white,
              }}
            />
          </>
        )}
      </picture>
    </Box>
  );
};

AspectMedia.displayName = 'AspectMedia';
