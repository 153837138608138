import texts from '../locales/de/common.json';

export const I18NEXT_OPTIONS = {
  defaultNS: 'common',
  lng: 'de',
  resources: {
    de: {
      common: texts,
    },
  },
};
