import { Box, useTheme } from '@mui/material';
import { Button } from '@portals/core/src/components/Button/Button';
import { Dialog } from '@portals/core/src/components/Dialog/Dialog';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import { Pill } from '@portals/core/src/components/Pill/Pill';
import { Typography } from '@portals/core/src/components/Typography/Typography';
import { Close } from '@portals/icons';
import { get } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { if6CssPrefix } from '../../../config';
import { isIF6Context } from '../../../utils';
import { GalleryCarousel } from '../GalleryCarousel/GalleryCarousel';
import { GalleryCarouselItemType } from '../GalleryCarousel/GalleryCarouselItem';
import { getStyles } from './GalleryModal.styles';

export interface Props {
  open: boolean;
  onClose: () => void;
  items: Array<GalleryCarouselItemType>;
  currentImagePosition: number | null;
}

export const GalleryModal = ({ open, onClose, items, currentImagePosition }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getStyles(theme);

  const isIF6Scope = isIF6Context();
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const ellipsisStyle = {
    display: 'inline-block',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '40ch',
  };

  const getDescriptionFromGalleryCarouselItem = () => {
    return get(items[activeSlide], 'props.description', '');
  };

  const getParentContainer = () => {
    if (isIF6Scope && typeof window !== 'undefined') {
      return document.getElementsByClassName(if6CssPrefix)[0];
    }
    return undefined;
  };

  const DialogCloseButton = () => (
    <Box sx={styles.closeButtonContainer} className="gallery-modal-close-button">
      <Button
        variant="inverted"
        size="header"
        color="secondary"
        onClick={onClose}
        sx={styles.closeButton}
        endIcon={<Close sx={styles.closeIcon} />}
      >
        {t('close')}
      </Button>
      <IconButton onClick={onClose} circular sx={styles.closeIconButton} aria-label={t('close')}>
        <Close />
      </IconButton>
    </Box>
  );

  return (
    <Dialog
      sx={styles.dialog}
      container={getParentContainer()}
      className="gallery-modal-container"
      open={open}
      onClose={onClose}
      fullScreen
    >
      <DialogCloseButton />
      <Box sx={styles.dialogContent}>
        <Typography variant="h3" className="modal-title" sx={{ ...ellipsisStyle }} textAlign="center">
          {getDescriptionFromGalleryCarouselItem()}
        </Typography>
        <GalleryCarousel
          onActiveSlide={setActiveSlide}
          galleryCarouselItems={items}
          currentImagePosition={currentImagePosition}
        />
        <Pill
          sx={{ boxShadow: 'none', backgroundColor: theme.palette.grey[100] }}
          size="responsive"
          label={`${activeSlide + 1} / ${items.length}`}
        />
      </Box>
    </Dialog>
  );
};

GalleryModal.displayName = 'GalleryModal';
